<template>
  <VcWidget :title="$t('shared.opus.quick_connect.items_section.service_title')" prepend-icon="cube" size="lg">
    <div class="mt-5 flex flex-col gap-5 lg:gap-8">
      <div v-if="!readonly" class="flex flex-col space-y-5 md:flex-row md:items-end md:justify-center md:space-y-0">
        <div class="flex w-full grow items-end space-x-3">
          <VcInput
            v-model.trim="product.name"
            :label="$t('shared.opus.quick_connect.items_section.service_label')"
            required
            maxlength="256"
            class="w-full"
            :placeholder="$t('shared.opus.quick_connect.items_section.service_placeholder')"
          />
          <VcButton
            icon="plus-1"
            variant="outline"
            size="md"
            class="items-center text-center"
            @click="product.name && addProduct()"
          />
        </div>
        <div class="flex items-end"></div>
      </div>
      <div
        v-if="products.length"
        class="flex flex-col bg-additional-50 shadow-sm lg:rounded"
        :class="{ 'lg:border': products.length, 'rounded-sm border border-neutral-200': isMobile }"
      >
        <VcTable
          class="border-red-800' flex flex-col bg-additional-50 shadow-sm lg:rounded"
          :pages="pages"
          :page="page"
          layout="table-auto"
          :columns="columns"
          :items="paginatedProducts"
          :hide-default-footer="false"
          @page-changed="onPageChange"
        >
          <template #header>
            <thead class="border-b border-neutral-200">
              <tr>
                <th class="flex w-2/3 flex-row px-5 py-3 text-left font-extrabold">
                  <!-- {{ $t("shared.opus.quick_connect.items_section.table.product") }} -->
                  {{ $t("shared.opus.quick_connect.items_section.table.service") }}
                </th>
                <th class="w-1/6 px-5 py-3 text-left font-extrabold"></th>
                <th class="w-2 px-5 py-3 text-left"></th>
              </tr>
            </thead>
          </template>

          <template #desktop-body>
            <tr
              v-for="(_product, index) in paginatedProducts"
              :key="index"
              :class="{ 'border-b border-neutral-200': index !== products.length - 1 }"
              class="even:bg-neutral-50"
            >
              <td class="overflow-hidden text-ellipsis p-5 font-bold">
                <span v-html-safe="replaceUnicode(_product.name)" />
              </td>

              <td class="overflow-hidden text-ellipsis p-5 font-normal"></td>
              <td class="items-center overflow-hidden p-3 text-center">
                <button
                  v-if="!readonly"
                  type="button"
                  :class="{ 'cursor-pointer': _product.removable }"
                  @click="_product.removable && removeItem(_product)"
                  @keydown.enter="_product.removable && removeItem(_product)"
                >
                  <VcIcon size="xs" name="x" :class="{ 'text-neutral-500': !_product.removable }" />
                </button>
              </td>
            </tr>
          </template>

          <template #mobile-item="itemObject">
            <div class="flex flex-col border-b border-b-neutral-200 bg-additional-50 shadow-sm">
              <div class="flex flex-row items-start justify-between overflow-hidden text-ellipsis">
                <span class="px-5 pb-2 pt-5 md:pb-5">{{ itemObject.item?.name }}</span>

                <button
                  v-if="!readonly"
                  type="button"
                  class="ml-auto flex items-center px-3 pb-1 pt-6 md:pb-3"
                  @click="itemObject.item.removable && removeItem(itemObject.item)"
                  @keydown.enter="itemObject.item.removable && removeItem(itemObject.item)"
                >
                  <VcIcon center size="xs" name="x" :class="{ 'text-neutral-500': !itemObject.item.removable }" />
                </button>
              </div>
              <div class="flex flex-row items-center overflow-hidden text-ellipsis px-5"></div>
              <div class="mr-auto overflow-hidden p-3 text-center"></div>
            </div>
          </template>

          <template #desktop-skeleton>
            <tr v-for="i in itemsPerPage" :key="i" class="even:bg-neutral-50">
              <td v-for="column in columns.length" :key="column" class="px-5 py-4">
                <div class="h-5 animate-pulse bg-neutral-200" />
              </td>
            </tr>
          </template>
        </VcTable>
      </div>
    </div>
  </VcWidget>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { without } from "lodash";
import { computed, ref, shallowRef, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { replaceUnicode } from "@/shared/catalog";
import { RequestType } from "@/shared/opus/quick-connect/types";
import type { ShortProductType, ITemsAndNotesPayload } from "@/shared/opus/quick-connect";

const emit = defineEmits<IEmits>();

const props = withDefaults(defineProps<IProps>(), {
  requestType: RequestType.Services,
});

interface IProps {
  requestType?: RequestType;
  items?: ShortProductType[];
  readonly?: boolean;
}
interface IEmits {
  (event: "change:services", value: ShortProductType[]): ShortProductType[];
}

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");
const page = ref(1);
const itemsPerPage = ref(5);

const { t } = useI18n();

const products = ref<ShortProductType[]>([]);

const product = ref<ShortProductType>({ name: "", quantity: 1, removable: true });

const pages = computed(() => Math.ceil(products.value.length / itemsPerPage.value));
const paginatedProducts = computed<ShortProductType[]>(() =>
  products.value.slice((page.value - 1) * itemsPerPage.value, page.value * itemsPerPage.value),
);

function addProduct() {
  products.value = [...products.value, { name: product.value.name, quantity: product.value.quantity, removable: true }];
  product.value = { name: "", quantity: 1, removable: true };
}

function removeItem(selectedProduct: ShortProductType) {
  products.value = without(products.value, selectedProduct);

  if (!paginatedProducts.value.length && page.value > 1) {
    page.value = --page.value;
  }
}

function onPageChange(newPage: number): void {
  page.value = newPage;
}

const columns = computed<ITableColumn[]>(() => [
  {
    id: "Product",
    title: "Product",
    classes: "w-9/12",
  },
  {
    id: "Sku",
    title: "Sku",
    classes: "w-1/4",
  },
  {
    id: "Quantity",
    title: "Quantity",
  },
  {
    id: "Actions",
    classes: "w-2",
    align: "right",
  },
]);

watch([products], ([newProducts]) => {
  emit("change:services", newProducts);
});
onMounted(() => {
  if (props.items) {
    products.value = props.items;
  }
});
watch(
  () => props.items,
  (value) => {
    if (value) {
      products.value = value;
      product.value.name = "";
    }
  },
);
</script>
