import { SUPPLIER_SORTING_LIST } from "@/core/constants";
import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import searchSuppliersQueryDocument from "./searchSuppliersQuery.graphql";
import type { Query, QuerySuppliersArgs, SupplierConnection } from "@/core/api/graphql/types";

export async function searchSuppliers(payload: Omit<QuerySuppliersArgs, "storeId" | "userId">): Promise<SupplierConnection> {
  const { storeId, userId } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "suppliers">>, QuerySuppliersArgs>({
    query: searchSuppliersQueryDocument,
    variables: {
      storeId,
      userId,
      ...payload,
      sort: payload.sort || SUPPLIER_SORTING_LIST[0].id,
    },
  });

  return data.suppliers!;
}
