import { useAuth } from "@/core/composables";
import { QueryParamName } from "@/core/enums";
import { useUser } from "@/shared/account/composables/useUser";
import { useTermsAndConditions } from "@/shared/opus";
import { accountRoutes } from "./account";
import { checkoutRoutes } from "./checkout";
import { corporateRoutes } from "./company";
import { opCategoriesAndSuppliersRoutes } from "./opus/op-categories-and-suppliers";
import type { RouteRecordRaw } from "vue-router";
import Error403 from "@/pages/403.vue";
import Error404 from "@/pages/404.vue";
import Error500 from "@/pages/500.vue";
import Faq from "@/pages/opus/faq.vue";
import AboutUs from "@/pages/opus/op-about-us.vue";
import OpHome from "@/pages/opus/op-home.vue";
import TermsAndConditions from "@/pages/opus/terms-and-conditions.vue";

const callback = () => import("@/pages/auth/callback.vue");
const SingInPage = () => import("@/pages/sign-in.vue");
const SignUpPage = () => import("@/pages/sign-up.vue");
const ConfirmEmail = () => import("@/pages/confirm-email.vue");
const ConfirmInvitation = () => import("@/pages/confirm-invitation.vue");
const Invitation = () => import("@/pages/invite.vue");
const ForgotPassword = () => import("@/pages/forgot-password.vue");
const ResetPassword = () => import("@/pages/reset-password.vue");
const ChangePassword = () => import("@/pages/change-password.vue");
const BlockedPage = () => import("@/pages/blocked.vue");
const Account = () => import("@/pages/account/index.vue");
const Company = () => import("@/pages/company/index.vue");
const BulkOrder = () => import("@/pages/bulk-order.vue");
const CompareProducts = () => import("@/pages/compare-products.vue");
const Cart = () => import("@/pages/cart.vue");
const Search = () => import("@/pages/search.vue");
const Catalog = () => import("@/pages/catalog.vue");
const Category = () => import("@/pages/category.vue");
const Product = () => import("@/pages/product.vue");
const Branch = () => import("@/pages/branch.vue");
//OPUS
const SearchResult = () => import("@/pages/opus/op-search-result.vue");
const OpRegistrationInvitation = () => import("@/pages/opus/op-registration-invitation.vue");
const OpCategoriesAndSuppliers = () => import("@/pages/opus/op-home.vue");
const OpSignUpPage = () => import("@/pages/opus/op-sign-up.vue");
const OpWelcome = () => import("@/pages/op-welcome.vue");
const OpQuickConnect = () => import("@/pages/opus/op-quick-connect.vue");
const OpQuickConnectHistory = () => import("@/pages/opus/op-quick-connect-history.vue");
const OpTempPass = () => import("@/pages/opus/op-temp-pass.vue");
const OrderConfirm = () => import("@/pages/opus/op-order-confirm.vue");
const OrderCancel = () => import("@/pages/opus/op-order-cancel.vue");
// !OPUS

// Private development pages
const DemoLanding = () => import("@/pages/demo-landing.vue");
const Welcome = () => import("@/pages/welcome.vue");
const PushMessage = () => import("@/pages/push-message.vue");
const Matcher = () => import("@/pages/matcher/matcher.vue");

export const mainRoutes: RouteRecordRaw[] = [
  // { path: "/", name: "Home", component: Home, meta: { public: true } },
  // OPUS
  { path: "/", name: "Home", meta: { public: true }, redirect: { name: opCategoriesAndSuppliersRoutes[0].name } },
  // !OPUS
  { path: "/auth/callback", name: "AuthCallback", component: callback, meta: { public: true } },
  { path: "/403", name: "NoAccess", component: Error403, meta: { public: true } },
  { path: "/404", name: "NotFound", component: Error404, meta: { public: true } },
  { path: "/500", name: "InternalError", component: Error500, meta: { public: true } },
  { path: "/sign-in", name: "SignIn", component: SingInPage, meta: { public: true } },
  // { path: "/sign-up", name: "SignUp", component: SignUpPage, meta: { public: true } },
  // OPUS
  { path: "/sign-up", name: "SignUp", component: OpSignUpPage, meta: { public: true } },
  // !OPUS
  { path: "/confirm-invitation", name: "ConfirmInvitation", component: ConfirmInvitation, meta: { public: true } },
  { path: "/invitation", name: "Invitation", component: Invitation, meta: { public: true } },
  { path: "/forgot-password", name: "ForgotPassword", component: ForgotPassword, meta: { public: true } },
  { path: "/reset-password", name: "ResetPassword", component: ResetPassword, meta: { public: true } },
  { path: "/change-password", name: "ChangePassword", component: ChangePassword, meta: { public: false } },
  // Not part of OPUS flow
  { path: "/set-password", name: "SetPassword", component: ResetPassword, meta: { public: true } },
  //
  { path: "/blocked", name: "Blocked", component: BlockedPage, meta: { public: true } },
  { path: "/account/confirmemail", name: "ConfirmEmail", component: ConfirmEmail, meta: { public: true } },
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: accountRoutes,
    redirect: { name: accountRoutes[0].name },
    meta: { requiresAuth: true },
  },
  {
    path: "/company",
    name: "Company",
    component: Company,
    children: corporateRoutes,
    redirect: { name: corporateRoutes[0].name },
    meta: {
      requiresAuth: true,
      requiresOrganization: true,
    },
  },
  { path: "/branch/:branchId", name: "BranchPage", component: Branch, props: true },
  {
    path: "/search",
    name: "Search",
    component: SearchResult,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter(_, from, next) {
      if (_.query[QueryParamName.SearchPhrase]) {
        next();
      } else {
        next({ name: "CategoriesAndSuppliersHome", replace: true });
      }
    },
  },
  {
    path: "/search/suppliers",
    name: "SearchSuppliers",
    component: SearchResult,
    meta: { requiresAuth: true },
  },
  {
    path: "/search/categories",
    name: "SearchCategories",
    component: SearchResult,
    meta: { requiresAuth: true },
    beforeEnter(_, from, next) {
      if (from.name === "Search") {
        next();
      } else {
        next({ name: "CategoriesAndSuppliersHome", replace: true });
      }
    },
  },
  {
    path: "/search/products",
    name: "SearchProducts",
    component: Search,
    meta: { requiresAuth: true },
    beforeEnter(_, from, next) {
      if (from.name != next.name) {
        const { fetchUser } = useUser();
        void fetchUser();
      }
      next();
    },
  },
  { path: "/bulk-order", name: "BulkOrder", component: BulkOrder, meta: { requiresAuth: true } },
  { path: "/compare", name: "CompareProducts", component: CompareProducts },
  { path: "/cart", name: "Cart", component: Cart },
  // { path: "/successful-registration", name: "Welcome", component: Welcome, meta: { public: true } },
  ...checkoutRoutes,
  // { path: "/catalog", name: "Catalog", component: Catalog, props: true },
  { path: "/category/:categoryId", name: "Category", component: Category, props: true, meta: { requiresAuth: true } },
  { path: "/product/:productId", name: "Product", component: Product, props: true, meta: { requiresAuth: true } },
  { path: "/push-message/:messageId", name: "PushMessage", component: PushMessage, props: true },

  // OPUS
  {
    path: "/successful-registration/:status(new|unverified)",
    name: "OpWelcome",
    component: OpWelcome,
    props: true,
    meta: { public: true },
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: Catalog,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter(_, from, next) {
      if (from.name != next.name) {
        const { fetchUser } = useUser();
        fetchUser();
      }
      next();
    },
  },
  {
    path: "/home",
    name: "CategoriesAndSuppliers",
    component: OpCategoriesAndSuppliers,
    children: opCategoriesAndSuppliersRoutes,
    redirect: { name: opCategoriesAndSuppliersRoutes[0].name },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/registration-invitation",
    name: "RegistrationInvitation",
    component: OpRegistrationInvitation,
    meta: { public: true },
  },
  {
    path: "/quick-connect",
    name: "OpQuickConnect",
    component: OpQuickConnect,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/account/quick-connect-history/:quickConnectRequestId",
    meta: { requiresAuth: true },
    props: true,
    name: "OpQuickConnectDetails",
    component: OpQuickConnect,
  },
  { path: "/about-us", name: "AboutUs", component: AboutUs, meta: { public: true } },
  { path: "/faq", name: "FAQ", component: Faq, meta: { requiresAuth: true } },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
    meta: { requiresAuth: true },
    beforeEnter(_, from, next) {
      const { isTermsAccepted } = useTermsAndConditions();
      if (!isTermsAccepted.value) {
        _.meta = { ..._.meta, layout: "Secure" };
      }
      next();
    },
  },
  {
    path: "/temporary-pass",
    name: "TemporaryPass",
    component: OpTempPass,
    meta: { requiresAuth: true, layout: "Secure" },
    beforeEnter(_, from, next) {
      const { isTemporaryPassword } = useUser();
      if (!isTemporaryPassword.value) {
        next({ name: "CategoriesAndSuppliersHome", replace: true });
      } else {
        next();
      }
    },
  },
  {
    path: "/orders/:orderId/confirm",
    name: "OrderConfirm",
    component: OrderConfirm,
    props: true,
    meta: { public: true },
  },
  {
    path: "/orders/:orderId/cancel",
    name: "OrderCancel",
    component: OrderCancel,
    props: true,
    meta: { public: true },
  },
  {
    path: "/opus-registration",
    name: "OpRegistrationWelcome",
    meta: { public: true, showRegistrationModal: true },
    component: OpHome,
    beforeEnter(_, from, next) {
      const accessTokenQueryParam = _.query.access_token as string;
      const refreshTokenQueryParam = _.query.refresh_token as string;
      const expiresAtQueryParam = _.query.expires_at as string;
      const { setAccessToken, setRefreshToken, setExpiresAtTimestamp } = useAuth();
      const { isAuthenticated } = useUser();

      if (accessTokenQueryParam && refreshTokenQueryParam && expiresAtQueryParam && !isAuthenticated.value) {
        setAccessToken(accessTokenQueryParam);
        setRefreshToken(refreshTokenQueryParam);
        setExpiresAtTimestamp(+expiresAtQueryParam);

        window.location.href = "/home?welcome";
      } else {
        next({ name: "SignIn" });
      }
    },
  },
  // !OPUS
  /** NOTE: Always leave it last. */
  { path: "/:pathMatch(.*)*", name: "Matcher", component: Matcher, props: true },
];
