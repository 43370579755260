<template>
  <VcContainer>
    <div class="flex md:gap-x-6">
      <!-- First column-->
      <div v-if="!isMobile" class="flex w-60 shrink-0 flex-col gap-6">
        <VcCarousel
          v-if="false"
          :slides="carouselBannerImages"
          :options="supplierTilesOptions"
          class="z-5 px-1 [--navigation-offset:3rem] lg:mb-2"
        >
          <template #slide="{ slide: item }">
            <VcImage
              :src="item.imageUrl"
              size-suffix="sm"
              :alt="item.alt"
              class="z-10 w-full cursor-pointer rounded object-contain"
              lazy
              @click="searchForSupplierProducts(item)"
            />
          </template>
        </VcCarousel>
        <router-link :to="{ name: 'SupplierManagement' }">
          <img :src="banner1" alt="banner" class="pointer-events-none w-full rounded object-cover" lazy />
        </router-link>
        <router-link :to="{ name: 'Suppliers' }">
          <img :src="banner2" alt="banner" class="pointer-events-none w-full rounded object-cover" lazy />
        </router-link>
      </div>

      <!-- Second column-->
      <router-view class="flex max-w-full flex-col gap-y-5 md:px-0" />
    </div>
  </VcContainer>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints, whenever } from "@vueuse/core";
import { computed, onMounted, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useCategories, useGoogleAnalytics, usePageHead } from "@/core/composables";
import { QueryParamName } from "@/core/enums/query-param-name.enum";
import { useModal } from "@/shared/modal";
import { useSuppliers } from "@/shared/opus/categories-and-suppliers";
import { opUseQuickConnect } from "@/shared/opus/quick-connect";
import OpWelcomeOpusModal from "@/shared/opus/account/components/op-welcome-opus-modal.vue";
import { useUser } from "@/shared/account/composables";

import GiImg from "@/assets/opus/images/suppliers/LR-2024-001-Global-1.png";
import GraingerImg from "@/assets/opus/images/suppliers/LR-2024-001-Grainger-1.png";
import OdpImg from "@/assets/opus/images/suppliers/LR-2024-001-ODP-1.png";
import MscImg from "@/assets/opus/images/suppliers/LR-2024-001-MSC-1.png";
import banner1 from "@/assets/opus/images/suppliers/banner1.png";
import banner2 from "@/assets/opus/images/suppliers/banner2.png";
import blue_private_banner from "@/assets/opus/images/suppliers/blue_private_banner.png";

type CarouselImageType = {
  imageUrl: string;
  alt: string;
};

const { isPrivateSector } = useUser();

const carouselBannerImages = computed(() => {
  if (isPrivateSector.value) {
    return [
      {
        imageUrl: blue_private_banner,
        alt: "Private Sector Banner",
      },
    ];
  } else {
    return [
      {
        imageUrl: GiImg,
        alt: "Global Industrials",
      },
      {
        imageUrl: GraingerImg,
        alt: "Grainger",
      },
      {
        imageUrl: OdpImg,
        alt: "ODP",
      },
      {
        imageUrl: MscImg,
        alt: "MSC",
      },
    ];
  }
});

const { t } = useI18n();
const route = useRoute();
const titleKey = computed(() => {
  const routeKey =
    route.name === "Suppliers" ? "suppliers_title" : route.name === "Category" ? "categories_title" : "main_title";
  return t(`pages.suppliers_and_categories.meta.${routeKey}`);
});

usePageHead({
  title: titleKey,
});

const router = useRouter();
const { fetchSuppliers, getFacetsQueryForSearchProducts } = useSuppliers();
const { fetchCategories } = useCategories();
const { fetchSuppliersPurposes } = opUseQuickConnect();
const { openModal } = useModal();
const breakpoints = useBreakpoints(breakpointsTailwind);
const ga = useGoogleAnalytics();
const isMobile = breakpoints.smaller("lg");
const mobileSidebarVisible = ref(false);
const showWelcomeModal = ref(false);

function hideMobileSidebar(): void {
  mobileSidebarVisible.value = false;
}

function openRegistrationWelcomeModal() {
  openModal({
    component: OpWelcomeOpusModal,
    props: {},
  });
}

function searchForSupplierProducts(item: CarouselImageType) {
  ga.imageClick(item.imageUrl, item.alt);

  let supplierOuterId = "";
  switch (item) {
    case carouselBannerImages.value[0]:
      supplierOuterId = "4792";
      break;
    case carouselBannerImages.value[1]:
      supplierOuterId = "426";

      break;
    case carouselBannerImages.value[2]:
      supplierOuterId = "282";

      break;
    case carouselBannerImages.value[3]:
      supplierOuterId = "5775";

      break;

    default:
      break;
  }

  void router.push({
    name: "SearchProducts",
    query: {
      [QueryParamName.Facets]: getFacetsQueryForSearchProducts(supplierOuterId),
    },
  });
}

const supplierTilesOptions: CarouselOptions = {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 600,
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 33,
    },
    1024: {
      slidesPerView: 1,
      spaceBetween: 57,
    },
    1200: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  },
};

onBeforeMount(() => {
  if (route.query.welcome !== undefined) {
    showWelcomeModal.value = true;
    const url = new URL(window.location.href);
    url.searchParams.delete("welcome");
    window.history.replaceState({}, document.title, url.pathname + url.search);
  }
});

onMounted(() => {
  if (route.name === "CategoriesAndSuppliersHome") {
    void fetchSuppliers();
  }
  void fetchCategories();
  void fetchSuppliersPurposes();
  if (showWelcomeModal.value) {
    openRegistrationWelcomeModal();
    showWelcomeModal.value = false;
  }
});

whenever(() => !isMobile.value, hideMobileSidebar);
</script>
