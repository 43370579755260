<template>
  <VcModal
    :title="$t('shared.opus.request_setup_modal.modal_title')"
    divide-actions-panel
    max-width="674px"
    is-mobile-fullscreen
    is-scrollable-on-desktop
  >
    <div class="border-secondary-250 -mx-2 mb-3 border-b md:-mx-5 md:mb-4 lg:mb-5">
      <div class="flex flex-col px-6 pb-2 xs:flex-row md:pb-3.5">
        <VcImage
          class="mr-7 size-[141px] object-contain md:size-[121px] lg:size-[141px]"
          :src="agency.supplier?.logo"
          :alt="agency.supplier?.name + ' logotype'"
          lazy
        />
        <div class="flex flex-col gap-2 overflow-hidden">
          <VcVendor :vendor="{ name: agency.supplier.name, id: agency.supplier.id }" class="mt-4 xs:m-0" />
          <div v-if="agency.supplier.description" class="text-14">
            {{ agency.supplier.description }}
          </div>
          <a
            class="cursor-pointer text-14 font-medium text-[--link-color]"
            :href="agency.supplier.externalLink"
            target="_blank"
          >
            {{ agency.supplier.externalLinkDescription || agency.supplier.externalLink }}
          </a>
        </div>
      </div>
    </div>

    <VcTypography tag="h3" class="mb-5">
      {{ $t("shared.opus.request_setup_modal.subtitle") }}
    </VcTypography>

    <form @submit="onSubmit">
      <VcLabel v-if="showAbCheckbox || showCcCheckbox" required>
        {{ $t("shared.opus.request_setup_modal.method_title") }}
      </VcLabel>
      <div v-if="showAbCheckbox || showCcCheckbox" class="mb-7 mt-1.5">
        <div class="flex flex-wrap gap-8">
          <VcCheckbox v-if="showAbCheckbox" v-model="accountBillingMethod">
            {{ $t("shared.opus.request_setup_modal.account_billing") }}
          </VcCheckbox>

          <VcCheckbox v-if="showCcCheckbox" v-model="creditCardMethod">
            {{ $t("shared.opus.request_setup_modal.credit_card") }}
          </VcCheckbox>
        </div>
      </div>

      <VcLabel required>
        {{ $t("shared.opus.request_setup_modal.radio_title") }}
      </VcLabel>
      <div class="mb-7 mt-1.5">
        <div class="flex flex-wrap gap-8">
          <VcRadioButton v-model="taxExempt" value="1" :label="$t('common.buttons.yes')" />

          <VcRadioButton v-model="taxExempt" value="0" :label="$t('common.buttons.no')" />
        </div>
      </div>

      <VcTextarea
        v-model="notes"
        :placeholder="$t('shared.opus.request_setup_modal.notes_placeholder')"
        :label="$t('shared.catalog.supplier_request_modal.notes')"
        :error="!!errors.notes"
        :message="errors.notes"
        class="block w-full max-w-[625px]"
        :max-length="1000"
        rows="4"
      />
    </form>
    <template #actions="{ close }">
      <div class="flex space-x-3" :class="[isMobile ? 'w-1/2 grow' : 'w-full justify-between']">
        <VcButton color="secondary" class="w-1/2 grow md:w-auto md:grow-0 md:px-5" variant="outline" @click="close">
          {{ $t("common.buttons.cancel") }}
        </VcButton>

        <VcButton
          class="min-w-[145px] px-2 lg:mr-auto"
          :class="[isMobile && 'w-1/2 grow']"
          :disabled="!accountBillingMethod && !creditCardMethod && (showAbCheckbox || showCcCheckbox)"
          @click="onSubmit()"
        >
          {{ $t("common.buttons.submit") }}
        </VcButton>
      </div>
    </template>
  </VcModal>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useField, useForm } from "vee-validate";
import { computed } from "vue";
import { bool, object, string } from "yup";
import { PaymentMethodAvailabilityType } from "@/core/api/graphql/types";
import { globals } from "@/core/globals";
import type { InputRequestSetupCommand } from "@/core/api/graphql/types";
import type { ExtendedSupplierAgencyType } from "@/shared/company";

interface IEmits {
  (event: "result", request: InputRequestSetupCommand): void;
}

interface IProps {
  agency: ExtendedSupplierAgencyType;
}

const emit = defineEmits<IEmits>();
const props = defineProps<IProps>();

const { userId } = globals;

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");

const showAbCheckbox = computed(
  () =>
    props.agency.paymentMethods?.find((item) => item.name === "PurchaseOrderPaymentMethod")?.availability ===
    PaymentMethodAvailabilityType.Disabled,
);
const showCcCheckbox = computed(
  () =>
    props.agency.paymentMethods?.find(
      (item) => item.name === "GetBalancePaymentMethod" || item.name === "SkyflowPaymentMethod",
    )?.availability === PaymentMethodAvailabilityType.Disabled,
);

const schema = toTypedSchema(
  object({
    accountBillingMethod: bool().label("accountBillingMethod").nullable(),
    creditCardMethod: bool().label("creditCardMethod").nullable(),
    taxExempt: string().label("taxExempt"),
    notes: string().label("Notes").max(1000),
  }),
);

const { errors, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    accountBillingMethod: showAbCheckbox.value ? false : undefined,
    creditCardMethod: showCcCheckbox.value ? false : undefined,
    taxExempt: "1",
    notes: "",
  },
  validateOnMount: false,
});

const { value: accountBillingMethod } = useField<boolean>("accountBillingMethod");
const { value: creditCardMethod } = useField<boolean>("creditCardMethod");
const { value: taxExempt } = useField<string>("taxExempt");
const { value: notes } = useField<string>("notes");

const onSubmit = handleSubmit(() => {
  emit("result", {
    accountBillingMethod: accountBillingMethod.value,
    creditCardMethod: creditCardMethod.value,
    taxExempt: !!taxExempt.value,
    notes: notes.value,
    supplierId: props.agency.supplier.id,
    userId: userId,
  });
});
</script>
