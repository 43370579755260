<template>
  <nav
    class="mobile-menu fixed z-50 flex size-full flex-col bg-[--mobile-menu-bg-color] text-[--mobile-menu-text-color]"
  >
    <header class="flex h-20 shrink-0 items-center gap-x-3 px-6">
      <div class="grow pr-6">
        <!-- OPUS -->
        <!-- <span v-if="organization" class="line-clamp-2 text-xl italic leading-[22px] text-[--mobile-menu-text-color]">
          {{ organization?.name }}
        </span>

        <VcImage v-else :src="$cfg.logo_inverted_image" :alt="$context.storeName" class="max-h-9" lazy /> -->
        <VcImage
          :src="$cfg.logo_inverted_image"
          :alt="$context.storeName"
          class="max-h-14 cursor-pointer"
          lazy
          @click="
            $emit('close');
            router.push('/home');
          "
        />
        <!-- !OPUS -->
      </div>

      <router-link
        v-if="$cfg.push_messages_enabled && isAuthenticated"
        :to="{ name: 'Notifications' }"
        class="-mr-4 appearance-none p-4"
        @click="$emit('close')"
      >
        <svg class="text-primary-200" height="32" width="32">
          <use href="/static/images/opus/icons/bell-mobile.svg#main" />
        </svg>
      </router-link>
      <!-- Language block -->
      <LanguageSelector v-if="supportedLocales.length > 1" />

      <button type="button" class="-mr-4 appearance-none p-4" @click="$emit('close')">
        <VcIcon name="x" class="text-[--mobile-menu-navigation-color]" :size="22" />
      </button>
    </header>

    <!-- OPUS -->
    <div v-if="!isAuthenticated" class="border-b border-additional-50 border-opacity-20 px-9 py-6">
      <div
        class="z-0 flex items-center rounded bg-[color:var(--color-link-light)] px-4 py-3 text-base font-normal text-[color:var(--color-footer-bottom-bg)]"
        :class="{ 'mobile-unauthorized-warning ': !isAuthenticated }"
      >
        <VcImage
          alt="info-icon"
          src="/static/images/opus/icons/information-circle.svg"
          class="mr-3 text-[color:var(--color-footer-bottom-bg)]"
          lazy
        />

        <span class="ml-0 mr-auto">{{ $t("shared.layout.header.bottom_header.warning_message") }}</span>
      </div>
    </div>
    <!-- !OPUS -->

    <section v-if="openedItem" class="grow divide-y divide-additional-50 divide-opacity-20 overflow-y-auto">
      <div class="flex flex-col px-10 py-6">
        <button type="button" class="appearance-none self-start text-[--mobile-menu-navigation-color]" @click="goBack">
          <VcIcon name="arrow-circle-left" size="lg" />
        </button>

        <h2
          v-if="openedItem.title && openedItem.title == 'Agency'"
          class="mt-5 text-2xl tracking-[0.01em] text-additional-50"
        >
          {{ user.contact?.firstName }}
        </h2>

        <h2 v-else-if="openedItem?.title" class="mt-5 text-2xl uppercase tracking-[0.01em] text-additional-50">
          {{ openedItem?.title }}
        </h2>
        <MultiOrganisationMenu v-if="openedItem.id === 'contact-organizations'" />
        <SettingsMenu v-else-if="openedItem.id === 'settings'" />
        <DefaultMenu v-else :items="sortedFilteredChildren" @close="$emit('close')" @select-item="selectMenuItem" />
        <!-- view all catalog link -->
        <template v-if="openedItem?.isCatalogItem && openedItem?.route">
          <div class="my-5 h-px bg-gradient-to-r from-accent to-transparent"></div>

          <a
            v-if="isExternalLink(openedItem.route)"
            class="view-all-link"
            :href="openedItem.route as string"
            target="_blank"
            @click="$emit('close')"
          >
            {{ $t("shared.layout.header.mobile.view_all_catalog") }}
          </a>
          <router-link v-else class="view-all-link" :to="openedItem.route" @click="$emit('close')">
            {{ $t("shared.layout.header.mobile.view_all_catalog") }}
          </router-link>
        </template>
      </div>
    </section>

    <MainMenu v-else :menu-item="homeMenuItem" @close="$emit('close')" @select-item="selectMenuItem" />

    <!-- OPUS:Footer -->
    <div class="mx-10 mb-12 flex flex-col pt-2 text-15 text-additional-50">
      <div class="mb-2 flex flex-row items-center justify-between">
        <a target="_blank" href="https://info.omniapartners.com/">
          <img src="/static/images/opus/omnia-footer-logo.svg#main" alt="omnia" class="h-12" lazy />
        </a>
        <a class="ml-auto mr-0" target="_blank" href="https://www.linkedin.com/company/omnia-partners">
          <img class="mr-2 h-9" src="/static/icons/social/linkedin-icon.svg" alt="linkedIn" lazy />
        </a>
        <a target="_blank" href="https://www.youtube.com/@omniapartners">
          <img class="h-9" src="/static/icons/social/youtube-icon.svg" alt="youtube" lazy />
        </a>
      </div>
      <div class="flex flex-row items-center justify-center">
        <router-link
          target="_blank"
          :to="{ name: 'TermsAndConditions', params: { sectionName: 'Terms&Conditions' } }"
          class="ml-1"
        >
          {{ $t("shared.layout.footer.terms_of_use_link") }}
        </router-link>
      </div>
    </div>
    <!-- !OPUS Footer -->

    <div
      class="mobile-menu__overlay fixed inset-y-0 right-0 hidden bg-additional-950/5 backdrop-blur-lg md:block"
      @click="$emit('close')"
    />
  </nav>
</template>

<script setup lang="ts">
import { computed, onMounted, shallowRef, triggerRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useNavigations } from "@/core/composables";
import { useLanguages } from "@/core/composables/useLanguages";
import { getLinkAttr } from "@/core/utilities";
import { useUser } from "@/shared/account";
import type { ExtendedMenuLinkType } from "@/core/types";
import type { RouteLocationRaw } from "vue-router";
import DefaultMenu from "@/shared/layout/components/header/_internal/mobile-menu/menus/default-menu.vue";
import MainMenu from "@/shared/layout/components/header/_internal/mobile-menu/menus/main-menu.vue";
import MultiOrganisationMenu from "@/shared/layout/components/header/_internal/mobile-menu/menus/multi-organisation-menu.vue";
import SettingsMenu from "@/shared/layout/components/header/_internal/mobile-menu/menus/settings-menu.vue";
import LanguageSelector from "@/shared/layout/components/language-selector/language-selector.vue";

interface IEmits {
  (event: "close"): void;
}

defineEmits<IEmits>();

const { t } = useI18n();

const { supportedLocales } = useLanguages();
const {
  isAuthenticated,
  organization,
  isCorporateMember,
  isMultiOrganization,
  // OPUS
  user,
  // !OPUS
} = useUser();
const { mobilePreSelectedMenuItem } = useNavigations();
const homeMenuItem = computed<ExtendedMenuLinkType>(() =>
  isAuthenticated.value
    ? {
        route: { name: "Dashboard" },
        title: t("shared.layout.header.mobile.account_menu.dashboard"),
        icon: "/static/images/dashboard/icons/dashboard-main.svg#main",
      }
    : {
        route: "/",
        title: t("shared.layout.header.menu.home"),
        icon: "/static/images/dashboard/icons/dashboard.svg#main",
      },
);

function isExternalLink(link?: RouteLocationRaw) {
  return "externalLink" in getLinkAttr(link);
}

const openedMenuItemsStack = shallowRef<ExtendedMenuLinkType[]>([]);

const openedItem = computed<ExtendedMenuLinkType | undefined>(
  () => openedMenuItemsStack.value[openedMenuItemsStack.value.length - 1],
);

const sortedFilteredChildren = computed(() => {
  const sortedChildren = (openedItem.value?.children || [])
    .slice()
    .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
  return sortedChildren.filter(canShowItem);
});

function canShowItem(item: ExtendedMenuLinkType) {
  if (item.id === "addresses" && isCorporateMember.value) {
    return false;
  }
  return !(item.id === "contact-organizations" && !isMultiOrganization.value);
}

function goBack() {
  openedMenuItemsStack.value.pop();
  triggerRef(openedMenuItemsStack);
}

function goMainMenu() {
  openedMenuItemsStack.value = [];
  triggerRef(openedMenuItemsStack);
}

function selectMenuItem(item: ExtendedMenuLinkType) {
  if (!item.children) {
    return;
  }
  openedMenuItemsStack.value.push(item);
  triggerRef(openedMenuItemsStack);
}

onMounted(() => {
  goMainMenu();

  if (mobilePreSelectedMenuItem.value) {
    selectMenuItem(mobilePreSelectedMenuItem.value);
  }
});

// OPUS
const router = useRouter();
// !OPUS
</script>

<style lang="scss" scoped>
.mobile-menu {
  --sidebar-max-width: 430px;
  --vc-radio-button-base-color: var(--mobile-menu-control-color);

  box-shadow: 5px 0 15px 0 rgba(0, 0, 0, 0.5);

  @apply md:max-w-[var(--sidebar-max-width)];
}

.view-all-link {
  @apply text-lg tracking-[0.01em] text-additional-50;
}

.mobile-menu__overlay {
  @apply left-[var(--sidebar-max-width)];
}

.is-visible .mobile-menu__overlay {
  animation: fadeIn 0.4s forwards;
}

@keyframes fadeIn {
  from {
    @apply opacity-0;
  }
  to {
    @apply opacity-100;
  }
}
</style>
